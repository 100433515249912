import { template as template_818c26da02f5425d9b2a3c7f04b93df3 } from "@ember/template-compiler";
const WelcomeHeader = template_818c26da02f5425d9b2a3c7f04b93df3(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
