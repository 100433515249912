import { template as template_96b7be625c7e4889a1cbc6ff7bcd01a4 } from "@ember/template-compiler";
const FKControlMenuContainer = template_96b7be625c7e4889a1cbc6ff7bcd01a4(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
